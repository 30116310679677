import { createContext, useContext, useState } from "react";

const Context = createContext();

const Provider = ({ children }) => {
  const [theme, setTheme] = useState("blue");
  const [darkTheme, setDarkTheme] = useState(true);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cardsVisible, setCardsVisible] = useState(false);

  const states = {
    theme,
    setTheme,
    darkTheme,
    setDarkTheme,
    active,
    setActive,
    loading,
    setLoading,
    cardsVisible,
    setCardsVisible,
  };

  return <Context.Provider value={states}>{children}</Context.Provider>;
};

export const useSite = () => useContext(Context);
export default Provider;
