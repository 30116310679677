import React, { useEffect, useCallback } from "react";
import { motion, useAnimation } from "framer-motion";
import {
  faSquarePollVertical,
  faUsers,
  faBuildingColumns,
  faGear,
  faMobileScreen,
  faFolderPlus,
  faCircleHalfStroke,
  faBrush,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSite } from "../context/SiteContext";
import { Link } from "react-router-dom";

const data = [
  {
    name: "Genel",
    items: [
      {
        title: "Genel Bakış",
        icon: faSquarePollVertical,
        url: "/genel-bakis"
      },
      {
        title: "Botlar",
        icon: faUsers,
        url: "/botlar"
      },
      {
        title: "Bankalar",
        icon: faBuildingColumns,
        url: "/bankalar"
      },
    ],
  },
  {
    name: "Yönetim",
    items: [
      {
        title: "Ayarlar",
        icon: faGear,
        url: "/ayarlar"
      },
      {
        title: "VNC",
        icon: faMobileScreen,
        url: "/vnc"
      },
      {
        title: "APK Oluşturucu",
        icon: faFolderPlus,
        url: "/apk-olusturucu"
      },
    ],
  },
];

const YanMenu = () => {
  const controls = useAnimation();
  const controlText = useAnimation();
  const controlTitleText = useAnimation();
  const controlThemeText = useAnimation();

  const { setTheme, setDarkTheme, active, setActive } = useSite();

  const showMore = useCallback(() => {
    controls.start({
      width: "250px",
      transition: { duration: 0.001 },
    });
    controlText.start({
      opacity: 1,
      display: "block",
      transition: { delay: 0.3 },
    });
    controlTitleText.start({
      opacity: 1,
      display: "block",
      transition: { delay: 0.3 },
    });
    controlThemeText.start({
      opacity: 1,
      display: "block",
      transition: { duration: 0.3 },
    });

    setActive(true);
  }, [controls, controlText, controlTitleText, controlThemeText, setActive]);

  const showLess = useCallback(() => {
    controls.start({
      width: "55px",
      transition: { duration: 0.001 },
    });

    controlText.start({
      opacity: 0,
      display: "none",
    });

    controlTitleText.start({
      display: "none",
    });

    controlThemeText.start({
      opacity: 0,
      display: "none",
    });

    setActive(false);
  }, [controls, controlText, controlTitleText, controlThemeText, setActive]);

  function handleResize() {
    if (window.innerWidth >= 1076) {
      showMore();
    } else {
      showLess();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 50);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    showMore();
  }, [showMore]);

  function renkTema(bool) {
    if (bool) {
      document.getElementById("renk1").classList.add("hidden");
      document.getElementById("renk2").classList.remove("hidden");
    } else {
      document.getElementById("renk1").classList.remove("hidden");
      document.getElementById("renk2").classList.add("hidden");
    }
  }

  useEffect(() => {
    if (localStorage.getItem("dark")) {
      document.documentElement.classList.add("dark");
      setDarkTheme(true);
    } else {
      document.documentElement.classList.remove("dark");
      setDarkTheme(false);
    }
  }, []);

  function koyuTema() {
    document.documentElement.classList.toggle("dark");

    if (document.documentElement.classList.contains("dark")) {
      localStorage.setItem("dark", true);
      setDarkTheme(true);
    } else {
      localStorage.removeItem("dark");
      setDarkTheme(false);
    }
  }

  useEffect(() => {
    let localTheme = localStorage.getItem("theme");
    if (localTheme !== null) {
      setTheme(localTheme);
    } else {
      setTheme("gray");
    }
  }, []);

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <div className="sticky">
      <motion.div
        animate={controls}
        className=" top-0 h-screen overflow-y-auto max-w-[250px] animate duration-300 border-r border-gray-700 relative flex flex-col pt-10 pb-5 min-h-screen group"
      >
        {active && (
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={showLess}
            className="absolute text-xl bg-gray-800 text-white dark:bg-white dark:text-black px-2 py-1 rounded-l-xl cursor-pointer -right-0 top-5"
          />
        )}

        {!active && (
          <FontAwesomeIcon
            icon={faArrowRight}
            onClick={showMore}
            className="absolute text-xl bg-gray-800 text-white dark:bg-white dark:text-black px-2 py-1 rounded-l-xl cursor-pointer -right-0 top-5"
          />
        )}

        {active && (
          <div
            className={`max-w-[220px] h-[120px] flex justify-center mx-2 flex-col mb-4`}
          >
            <img className="hidden dark:inline" src="./logo_beyaz.png" alt="" />
            <img className="inline dark:hidden" src="./logo_siyah.png" alt="" />
          </div>
        )}

        {!active && (
          <div
            className={`max-w-[50px] flex justify-center mx-1 flex-col mb-2 mt-5`}
          >
            <img className="hidden dark:inline" src="./logo_beyaz.png" alt="" />
            <img className="inline dark:hidden" src="./logo_siyah.png" alt="" />
          </div>
        )}

        <div className="grow mt-2">
          {data.map((group, index) => (
            <div
              key={index}
              className={classNames(
                "my-2 pb-1 bg-gray-800 border border-gray-700",
                {
                  "mx-2 rounded-lg pt-2": active,
                  "mx-0 pt-01 space-y-1 rounded-l-2xl": !active,
                }
              )}
            >
              <motion.p
                animate={controlTitleText}
                className="mb-1 ml-4 text-sm font-bold text-white"
              >
                {group.name}
              </motion.p>

              {group.items.map((item, index2) => (
                <Link
                  key={index2}
                  to={item.url}
                  className="flex px-4 py-1 cursor-pointer hover:rounded-l-2xl mb-1 hover:bg-gray-600"
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    className={`text-lg w-6 transition-all text-gray-200`}
                  />
                  <motion.p
                    animate={controlText}
                    className={`ml-2 transition-all text-sm font-bold text-gray-200`}
                  >
                    {" "}
                    {item.title}
                  </motion.p>
                </Link>
              ))}
            </div>
          ))}
        </div>
        <div>
          <div
            onMouseEnter={() => renkTema(true)}
            onMouseLeave={() => renkTema(false)}
            className={classNames("mb-2", {
              hidden: !active,
            })}
          >
            <div
              id="renk1"
              className="flex cursor-pointer mx-2 px-4 py-2 bg-gray-800 border border-gray-700 items-center rounded-lg"
            >
              <FontAwesomeIcon
                icon={faBrush}
                className="text-lg w-6 text-gray-300"
              />
              <p className="ml-2 text-sm font-bold text-gray-300">
                Renk Teması
              </p>
            </div>

            <div
              id="renk2"
              className="flex hidden green:bg-green-600 mx-2 px-4 py-2 bg-gray-800 border border-gray-700 items-center rounded-lg space-x-4 justify-center"
            >
              <button
                onClick={() => handleThemeChange("green")}
                className="bg-green-600/85 hover:bg-green-600 rounded-full w-8 h-8 shadow-md shadow-green-500"
              ></button>
              <button
                onClick={() => handleThemeChange("red")}
                className="bg-red-600/85 hover:bg-red-600 rounded-full w-8 h-8 shadow-md shadow-red-500"
              ></button>
              <button
                onClick={() => handleThemeChange("blue")}
                className="bg-blue-600/85 hover:bg-blue-600 rounded-full w-8 h-8 shadow-md shadow-blue-500"
              ></button>
              <button
                onClick={() => handleThemeChange("gray")}
                className="bg-gray-600/85 hover:bg-gray-600 rounded-full w-8 h-8 shadow-md shadow-gray-500"
              ></button>
            </div>
          </div>
          <div>
            <div
              onClick={koyuTema}
              className={classNames(
                "px-4 py-2 select-none flex cursor-pointer bg-gray-800 border border-gray-700 hover:bg-gray-700 transition-colors items-center",
                {
                  "mx-2 rounded-lg": active,
                  "mx-0 rounded-l-2xl": !active,
                }
              )}
            >
              <FontAwesomeIcon
                icon={faCircleHalfStroke}
                className="text-lg w-6 text-gray-300"
              />
              <motion.p
                animate={controlThemeText}
                className="ml-2 text-sm font-bold text-gray-300"
              >
                <span className="hidden dark:inline">Açık Tema</span>
                <span className="inline dark:hidden">Koyu Tema</span>
              </motion.p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default YanMenu;
