import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import YanMenu from "../components/YanMenu";
import UstMenu from "../components/UstMenu";
import SiteContext from "../context/SiteContext";

const SiteLayout = () => {
  const kullanici = localStorage.getItem("kullanici");
  if (!kullanici) {
    return <Navigate to="/giris" replace />;
  }

  return (
    <div className="flex h-screen overflow-auto bg-gray-100 dark:bg-[#0e1217]">
      <SiteContext>
        <YanMenu />
        <div className="w-full text-white overflow-auto justify-center">
          <UstMenu />
          <div className="flex flex-col p-6 lg:p-12">
            <Outlet />
          </div>
        </div>
      </SiteContext>
    </div>
  );
};

export default SiteLayout;
