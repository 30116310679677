import React, { useEffect, useState } from "react";
import {
  faUsers,
  faBuildingColumns,
  faUserCheck,
  faUserPlus,
  faRobot,
  faMobile,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from "react-apexcharts";
import { useSite } from "../context/SiteContext";
import { api } from "../config.js";

const GenelBakis = () => {
  const {
    theme,
    darkTheme,
    loading,
    setLoading,
    cardsVisible,
    setCardsVisible,
  } = useSite();

  const [botlarData, setBotlarData] = useState([]);
  const [lastHourData, setLastHourData] = useState([]);
  const [sonucBulunamadi, setSonucBulunamadi] = useState(false);
  const [tablo1Yok, setTablo1Yok] = useState(false);
  const [haftalikBotIstatistigi, setHaftalikBotIstatistigi] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [totalCount, setTotalCount] = useState(0);
  const [totalBanks, setTotalBanks] = useState(0);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    const kullanici = localStorage.getItem("kullanici");
    if (!kullanici) {
      window.location.href = "/giris";
    }

    document.title = "Genel Bakış - Quantum Botnet";

    fetchBotlarData();

    setInterval(() => {
      fetchBotlarData();
    }, 60000);
  }, []);

  useEffect(() => {
    if (botlarData.length > 0) {
      groupDataByDayOfWeek(botlarData);
      groupDataByAndroidVersion(botlarData);

      const groupedData = groupDataByAndroidVersion(botlarData);
      const labels = Object.keys(groupedData);
      const series = Object.values(groupedData);
      const colors = labels.map(() => getRandomColor());

      setPieConfig((prevConfig) => ({
        ...prevConfig,
        series,
        options: {
          ...prevConfig.options,
          labels,
          colors,
        },
      }));

      const timer = setTimeout(() => {
        setLoading(false);
        setCardsVisible(true);
      }, 600);

      return () => clearTimeout(timer);
    } else {
      if (!loading) {
        setSonucBulunamadi(true);
      }
    }
  }, [botlarData, loading]);

  const fetchBotlarData = async () => {
    try {
      const response = await fetch(`${api}/api/botlar`);
      if (!response.ok) {
        setApiError(true);
      }
      const data = await response.json();
      setBotlarData(data);
      filterLastHourData(data);
      setTotalCount(data.length);
  
      // "paketler" verisi dolu olanların toplamını hesapla
      const totalBanks = data.reduce(
        (count, item) => (Array.isArray(item.paketler) && item.paketler.length > 0 ? count + 1 : count),
        0
      );
      setTotalBanks(totalBanks);
  
      setApiError(false);
  
      if (data.length === 0) {
        setSonucBulunamadi(true);
      } else {
        setSonucBulunamadi(false);
      }
  
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setApiError(true);
      setSonucBulunamadi(true);
      setLoading(false);
    }
  };
  


  const filterLastHourData = (data) => {
    const currentTime = new Date();
    const oneHourAgo = new Date(currentTime.getTime() - 15000);

    const filteredData = data.filter((item) => {
      const updatedAt = new Date(item.son_baglanti);
      return updatedAt > oneHourAgo;
    });

    setLastHourData(filteredData);
  };

  const groupDataByDayOfWeek = (data) => {
    const groupedData = {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    };

    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(
      currentDate.getDate() -
        currentDayOfWeek +
        (currentDayOfWeek === 0 ? -6 : 1)
    );
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

    data.forEach((item) => {
      /* const createdDate = new Date("2024-06-11 15:32:59"); */
      const createdDate = new Date(item.olusturulma_tarihi);

      if (createdDate >= firstDayOfWeek && createdDate <= lastDayOfWeek) {
        const dayOfWeek = createdDate.toLocaleString("en-US", {
          weekday: "long",
        });

        switch (dayOfWeek) {
          case "Monday":
            groupedData.Monday.push(item);
            break;
          case "Tuesday":
            groupedData.Tuesday.push(item);
            break;
          case "Wednesday":
            groupedData.Wednesday.push(item);
            break;
          case "Thursday":
            groupedData.Thursday.push(item);
            break;
          case "Friday":
            groupedData.Friday.push(item);
            break;
          case "Saturday":
            groupedData.Saturday.push(item);
            break;
          case "Sunday":
            groupedData.Sunday.push(item);
            break;
          default:
            break;
        }
      }
    });

    setHaftalikBotIstatistigi(groupedData);

    const noData = Object.values(groupedData).every(
      (dayData) => dayData.length === 0
    );
    if (noData) {
      setTablo1Yok(true);
    }
  };

  let renk = "#374151";

  if (theme === "blue" && darkTheme) {
    renk = "#2563EB";
  } else if (theme === "red" && darkTheme) {
    renk = "#DC2626";
  } else if (theme === "green" && darkTheme) {
    renk = "#16A34A";
  } else if (theme === "blue" && !darkTheme) {
    renk = "#60A5FA";
  } else if (theme === "red" && !darkTheme) {
    renk = "#F87171";
  } else if (theme === "green" && !darkTheme) {
    renk = "#4ADE80";
  }

  const chartConfig = {
    type: "bar",
    height: 240,
    series: [
      {
        name: "Yeni Bot",
        data: [
          haftalikBotIstatistigi["Monday"].length,
          haftalikBotIstatistigi["Tuesday"].length,
          haftalikBotIstatistigi["Wednesday"].length,
          haftalikBotIstatistigi["Thursday"].length,
          haftalikBotIstatistigi["Friday"].length,
          haftalikBotIstatistigi["Saturday"].length,
          haftalikBotIstatistigi["Sunday"].length,
        ],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      title: {
        show: "",
      },
      dataLabels: {
        enabled: false,
      },
      colors: [renk],
      plotOptions: {
        bar: {
          columnWidth: "40%",
          borderRadius: 2,
        },
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
        categories: [
          "Pazartesi",
          "Salı",
          "Çarşamba",
          "Perşembe",
          "Cuma",
          "Cumartesi",
          "Pazar",
        ],
      },
      yaxis: {
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#dddddd",
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 5,
          right: 20,
        },
      },
      fill: {
        opacity: 0.8,
      },
      tooltip: {
        theme: "dark",
      },
    },
  };

  const getRandomColor = () => {
    const getByte = () => 64 + Math.floor(Math.random() * 128); // 64-192 aralığında değerler
    return `rgb(${getByte()}, ${getByte()}, ${getByte()})`;
  };

  const groupDataByAndroidVersion = (data) => {
    const groupedData = {};

    data.forEach((item) => {
      const androidVersion = "Android " + item.metadata.surum;
      if (groupedData[androidVersion]) {
        groupedData[androidVersion]++;
      } else {
        groupedData[androidVersion] = 1;
      }
    });

    return groupedData;
  };

  const [pieConfig, setPieConfig] = useState({
    type: "pie",
    width: "100%",
    height: 280,
    series: [],
    options: {
      labels: [],
      chart: {
        toolbar: {
          show: false,
        },
      },
      title: {
        show: "",
      },
      dataLabels: {
        enabled: false,
      },
      colors: [],
      legend: {
        show: false,
      },
    },
  });

  const groupDataByModel = (data) => {
    const groupedData = {};

    data.forEach((item) => {
      const modelName = item.metadata.cihaz;
      if (groupedData[modelName]) {
        groupedData[modelName]++;
      } else {
        groupedData[modelName] = 1;
      }
    });

    return groupedData;
  };

  const createBarConfig = (data) => {
    const groupedData = groupDataByModel(data);
    const categories = Object.keys(groupedData);
    const dataValues = Object.values(groupedData);

    const barConfig = {
      type: "bar",
      height: 240,
      series: [
        {
          name: "Bu Modele Sahip Bot Sayısı",
          data: dataValues,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        title: {
          show: "",
        },
        dataLabels: {
          enabled: false,
        },
        colors: [renk],
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "40%",
            borderRadius: 2,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
          categories: categories,
        },
        yaxis: {
          labels: {
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#dddddd",
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: 5,
            right: 20,
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          theme: "dark",
        },
      },
    };

    return barConfig;
  };

  if (apiError) {
    return (
      <>
        <div className="flex justify-center items-center text-red-600">
          <FontAwesomeIcon icon={faWarning} className="text-5xl mr-4" />
          <h1 className="text-6xl">API ile iletişim kurulamadı.</h1>
        </div>
        <h1 className="flex justify-center items-center mt-8">
          API ile iletişime geçilmeye çalışılıyor... Lütfen bekleyiniz veya daha
          sonra tekrar deneyiniz.
        </h1>
      </>
    );
  } else {
    const barConfig = createBarConfig(botlarData);
    return (
      <>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 w-full max-w-7xl h-16 mb-[320px] lg:mb-16">
          <div className="flex bg-gray-800 border border-gray-700 p-4 rounded-lg">
            <div className="flex flex-shrink-0 items-center justify-center bg-red-800 border border-red-600 h-16 w-16 rounded">
              <FontAwesomeIcon
                icon={faBuildingColumns}
                className="text-lg w-6 h-6 text-white"
              />
            </div>
            <div className="flex-grow flex flex-col ml-4">
              <span className="text-xl font-bold">{totalBanks}</span>
              <div className="flex items-center justify-between">
                <span className="text-gray-500">Banka Bulunan Botlar</span>
              </div>
            </div>
          </div>
          <div className="flex p-4 bg-gray-800 border border-gray-700 rounded-lg">
            <div className="flex flex-shrink-0 items-center justify-center bg-orange-800 border border-orange-600 h-16 w-16 rounded">
              <FontAwesomeIcon
                icon={faUsers}
                className="text-lg w-6 h-6 text-white"
              />
            </div>
            <div className="flex-grow flex flex-col ml-4">
              <span className="text-xl font-bold">{totalCount}</span>
              <div className="flex items-center justify-between">
                <span className="text-gray-500">Toplam Bot</span>
              </div>
            </div>
          </div>
          <div className="flex p-4 bg-gray-800 border border-gray-700 rounded-lg">
            <div className="flex flex-shrink-0 items-center justify-center bg-green-800 border border-green-600 h-16 w-16 rounded">
              <FontAwesomeIcon
                icon={faUserCheck}
                className="text-lg w-6 h-6 text-white"
              />
            </div>
            <div className="flex-grow flex flex-col ml-4">
              <span className="text-xl font-bold">{lastHourData.length}</span>
              <div className="flex items-center justify-between">
                <span className="text-gray-500">Çevrimiçi Botlar</span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-flow-row-dense lg:grid-cols-3 md:grid-cols-2 gap-6 w-full max-w-7xl mb-9">
          <div className="bg-gray-800 border border-gray-700 p-4 rounded-lg col-span-2 w-[262px] lg:w-full">
            <div className="flex mb-2">
              <div className="flex flex-shrink-0 items-center justify-center bg-green-800 border border-green-600 h-10 w-10 rounded">
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="text-lg w-4 h-4 text-white"
                />
              </div>
              <div className="flex ml-4 items-center">
                <span className="text-xl font-bold">
                  Haftalık Yeni Bot İstatistiği
                </span>
              </div>
            </div>
            <hr className="my-4 h-[1px] bg-gray-500 border-0" />
            <div className="flex flex-col justify-center">
              {loading && (
                <div className="flex justify-center items-center">
                  <h1>Yükleniyor...</h1>
                </div>
              )}
              {(sonucBulunamadi || tablo1Yok) && (
                <div className="flex justify-center items-center text-yellow-400">
                  <FontAwesomeIcon
                    icon={faWarning}
                    className="text-lg w-4 h-4 mr-2"
                  />
                  <h1>Bu hafta gelen bot bulunmuyor.</h1>
                </div>
              )}
              {sonucBulunamadi && tablo1Yok && (
                <div className="flex justify-center items-center text-yellow-400">
                  <FontAwesomeIcon
                    icon={faWarning}
                    className="text-lg w-4 h-4 mr-2"
                  />
                  <h1>Bu hafta gelen bot bulunmuyor.</h1>
                </div>
              )}
              {cardsVisible && <Chart {...chartConfig} />}
            </div>
          </div>
          <div className="bg-gray-800 border border-gray-700 p-4 rounded-lg items-center w-[262px] lg:w-full">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center justify-center bg-green-800 border border-green-600 h-10 w-10 rounded">
                <FontAwesomeIcon
                  icon={faRobot}
                  className="text-lg w-4 h-4 text-white"
                />
              </div>
              <div className="flex ml-4 items-center">
                <span className="text-xl font-bold">Android Sürümleri</span>
              </div>
            </div>
            <hr className="my-4 h-[1px] bg-gray-500 border-0" />
            <div className="flex-initial justify-center">
              {loading && (
                <div className="flex justify-center items-center">
                  <h1>Yükleniyor...</h1>
                </div>
              )}
              {sonucBulunamadi && (
                <div className="flex justify-center items-center">
                  <h1>Veri Bulunamadı...</h1>
                </div>
              )}
              {cardsVisible && !sonucBulunamadi && (
                <Chart
                  options={pieConfig.options}
                  series={pieConfig.series}
                  type={pieConfig.type}
                  height={pieConfig.height}
                />
              )}
            </div>
          </div>
        </div>
        <div className="w-full max-w-7xl">
          <div className="bg-gray-800 border border-gray-700 p-4 rounded-lg w-full">
            <div className="flex mb-2">
              <div className="flex flex-shrink-0 items-center justify-center bg-blue-800 border border-blue-600 h-10 w-10 rounded">
                <FontAwesomeIcon
                  icon={faMobile}
                  className="text-lg w-4 h-4 text-white"
                />
              </div>
              <div className="flex ml-4 items-center">
                <span className="text-xl font-bold">Telefon Modelleri</span>
              </div>
            </div>
            <hr className="my-4 h-[1px] bg-gray-500 border-0" />
            <div className="flex-initial w-full">
              {loading && (
                <div className="flex justify-center items-center">
                  <h1>Yükleniyor...</h1>
                </div>
              )}
              {sonucBulunamadi && (
                <div className="flex justify-center items-center">
                  <h1>Veri Bulunamadı...</h1>
                </div>
              )}
              {cardsVisible && !sonucBulunamadi && (
                <Chart
                  options={barConfig.options}
                  series={barConfig.series}
                  type={barConfig.type}
                  height={barConfig.height}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default GenelBakis;
