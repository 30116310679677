import React, { useEffect, useState, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import Modal from "react-modal";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faUserPlus,
  faUserXmark,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSite } from "../context/SiteContext";
import { api } from "../config.js";

Modal.setAppElement("#root");

const Ayarlar = () => {
  const [kullanicilar, setKullanicilar] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [kullaniciAdi, setKullaniciAdi] = useState("");
  const [parola, setParola] = useState("");
  const { darkTheme } = useSite();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [modal2IsOpen, setModal2IsOpen] = useState(false);

  const toastOzellikler = {
    isLoading: false,
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Bounce,
  };

  useEffect(() => {
    document.title = "Ayarlar - Quantum Botnet";
    fetchKullanicilar();
  }, []);

  const fetchKullanicilar = async () => {
    try {
      const response = await fetch(
        `${api}/api/kullanici_listele`
      );
      const data = await response.json();
      setKullanicilar(data);
    } catch (error) {
      console.error("Kullanıcı listesi çekilemedi:", error);
    }
  };

  const kullaniciSil = async () => {
    try {
      var id = toast.loading("Kullanıcı siliniyor...", { autoClose: false });

      const response = await fetch(`${api}/api/kullanici_sil`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: selectedUserId }),
      });

      const result = await response.json();

      if (result.success) {
        setKullanicilar(
          kullanicilar.filter((kullanici) => kullanici.id !== selectedUserId)
        );
        toast.update(id, {
          render: "Kullanıcı başarıyla silindi.",
          type: "success",
          ...toastOzellikler,
        });
        closeModal();
      } else {
        toast.update(id, {
          render: result.message,
          type: "error",
          ...toastOzellikler,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: "Kullanıcı Silinemedi.",
        type: "error",
        ...toastOzellikler,
      });
    }
  };

  const topluKullaniciSil = async () => {
    let silmeSayaci = 0;
    try {
      var toastId = toast.loading(`Seçilen Kullanıcılar Siliniyor...`, {
        autoClose: false,
      });

      for (const id of selectedUsers) {
        const response = await fetch(
          `${api}/api/kullanici_sil`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: id }),
          }
        );

        const result = await response.json();

        if (result.success) {
          setKullanicilar(
            kullanicilar.filter((kullanici) => kullanici.id !== id)
          );
          silmeSayaci++;
        } else {
          toast.error(result.message);
        }
      }

      fetchKullanicilar();

      if (silmeSayaci !== 0) {
        toast.update(toastId, {
          render: `${silmeSayaci} Adet Kullanıcı Başarıyla Silindi.`,
          type: "success",
          ...toastOzellikler,
        });
      }

      setSelectedUsers([]);
      setModal2IsOpen(false);
    } catch (error) {
      toast.error("Seçilen Kullanıcılar Silinemedi.");
    }
  };

  const openModal = (id, admin) => {
    if (admin === "1") {
      toast.error("Yönetici Yetkisi Bulunan Kullanıcılar Silinemez.");
    } else {
      setSelectedUserId(id);
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedUserId(null);
  };

  const closeModal2 = () => {
    setModal2IsOpen(false);
  };

  const handleCheckboxChange = (id) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(id)
        ? prevSelectedUsers.filter((userId) => userId !== id)
        : [...prevSelectedUsers, id]
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      var id = toast.loading("Kullanıcı ekleniyor...", { autoClose: false });
      const response = await fetch(`${api}/api/kullanici_ekle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ kullanici_adi: kullaniciAdi, parola: parola }),
      });

      const result = await response.json();

      if (result.success) {
        setKullaniciAdi("");
        setParola("");
        toast.update(id, {
          render: "Kullanıcı başarıyla eklendi.",
          type: "success",
          ...toastOzellikler,
        });
        fetchKullanicilar();
      } else {
        toast.update(id, {
          render: result.message,
          type: "error",
          ...toastOzellikler,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: "Kullanıcı Eklenemedi.",
        type: "error",
        ...toastOzellikler,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Kullanıcı Adı",
        accessor: "kullanici_adi",
      },
      {
        Header: "Yetki Seviyesi",
        accessor: "yonetici",
        Cell: ({ value }) =>
          value === "1" ? (
            <span className="text-red-600">Yönetici</span>
          ) : (
            <span className="text-blue-600">Kullanıcı</span>
          ),
      },
      {
        Header: "İşlemler",
        Cell: ({ row }) => (
          <div className="flex items-center">
            <button
              disabled={row.original.yonetici === "1" ? true : false}
              className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded flex disabled:opacity-50"
              onClick={() => openModal(row.original.id, row.original.yonetici)}
            >
              <FontAwesomeIcon
                icon={faUserXmark}
                className="text-lg w-4 h-4 sm:mr-2 text-white"
              />
              <span className="hidden sm:block">Kullanıcıyı Sil</span>
            </button>

            {row.original.yonetici === "1" ? (
              ""
            ) : (
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(row.original.id)}
                checked={selectedUsers.includes(row.original.id)}
                className="ml-2 h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
              />
            )}
          </div>
        ),
      },
    ],
    [selectedUsers]
  );

  const data = useMemo(() => kullanicilar, [kullanicilar]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
    },
    usePagination
  );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={darkTheme ? "dark" : "light"}
      />

      <div className="w-full max-w-7xl mx-auto">
        <div className="bg-gray-800 border border-gray-700 p-4 rounded-lg w-full shadow-lg">
          <div className="flex mb-2">
            <div className="flex flex-shrink-0 items-center justify-center bg-green-800 border border-green-600 h-10 w-10 rounded">
              <FontAwesomeIcon
                icon={faUsers}
                className="text-lg w-4 h-4 text-white"
              />
            </div>
            <div className="flex ml-4 items-center">
              <span className="text-xl font-bold">Kullanıcılar</span>
            </div>
          </div>
          <hr className="my-4 h-[1px] bg-gray-500 border-0" />

          {selectedUsers.length > 0 && (
            <button
              className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded flex mb-3 items-center"
              onClick={() => setModal2IsOpen(true)}
            >
              <FontAwesomeIcon
                icon={faUserXmark}
                className="text-lg w-4 h-4 sm:mr-2 text-white"
              />
              <span>Seçilen Kullanıcıları Sil</span>
            </button>
          )}

          <div className="overflow-x-auto">
            <table
              {...getTableProps()}
              className="min-w-full bg-gray-700 border border-gray-600 rounded-lg"
            >
              <thead className="bg-gray-600">
                {headerGroups.map((headerGroup, key) => (
                  <tr key={key} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, key) => (
                      <th
                        {...column.getHeaderProps()}
                        key={key}
                        className="py-3 px-4 text-left text-sm font-medium text-gray-200 border-b border-gray-500"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="bg-gray-800">
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={key}
                      {...row.getRowProps()}
                      className="hover:bg-gray-600"
                    >
                      {row.cells.map((cell, key) => (
                        <td
                          {...cell.getCellProps()}
                          key={key}
                          className="py-3 px-4 text-sm text-gray-300 border-b border-gray-600"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="py-3 flex items-center justify-between">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700"
              >
                Önceki Sayfa
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700"
              >
                Sonraki Sayfa
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-300">
                  Sayfa: <span className="font-medium">{pageIndex + 1}</span> /{" "}
                  <span className="font-medium">{pageOptions.length}</span>
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                  className="mt-1 p-2 block bg-gray-700 border border-gray-600 rounded text-gray-300"
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize} adet göster
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="p-2 border border-gray-600 rounded text-gray-300 bg-gray-700 hover:bg-gray-600 disabled:opacity-50"
                >
                  {"<<"}
                </button>{" "}
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="p-2 border border-gray-600 rounded text-gray-300 bg-gray-700 hover:bg-gray-600 disabled:opacity-50"
                >
                  {"<"}
                </button>{" "}
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="p-2 border border-gray-600 rounded text-gray-300 bg-gray-700 hover:bg-gray-600 disabled:opacity-50"
                >
                  {">"}
                </button>{" "}
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="p-2 border border-gray-600 rounded text-gray-300 bg-gray-700 hover:bg-gray-600 disabled:opacity-50"
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 border border-gray-700 p-4 rounded-lg w-full mt-9 shadow-lg">
          <div className="flex mb-2">
            <div className="flex flex-shrink-0 items-center justify-center bg-green-800 border border-green-600 h-10 w-10 rounded">
              <FontAwesomeIcon
                icon={faUserPlus}
                className="text-lg w-4 h-4 text-white"
              />
            </div>
            <div className="flex ml-4 items-center">
              <span className="text-xl font-bold">Kullanıcı Ekle</span>
            </div>
          </div>
          <hr className="my-4 h-[1px] bg-gray-500 border-0" />
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-200">
                Kullanıcı Adı
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded text-gray-200"
                value={kullaniciAdi}
                onChange={(e) => setKullaniciAdi(e.target.value)}
                required
                placeholder="Kullanıcı Adını Giriniz.."
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-200">
                Parola
              </label>
              <input
                type="password"
                className="mt-1 p-2 block w-full bg-gray-700 border border-gray-600 rounded text-gray-200"
                value={parola}
                onChange={(e) => setParola(e.target.value)}
                required
                placeholder="Parolayı Giriniz.."
              />
            </div>
            <button
              type="submit"
              className="w-full bg-green-600 hover:bg-green-700 border border-green-500 text-white py-2 px-4 rounded-lg"
            >
              Kullanıcı Ekle
            </button>
          </form>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Kullanıcı Silme Onayı"
        className="bg-gray-800 border border-gray-700 p-4 rounded-lg max-w-md mx-auto mt-20 shadow-lg"
      >
        <h2 className="text-xl font-semibold text-white mb-4">Emin misiniz?</h2>
        <p className="text-gray-400 mb-4">
          Bu kullanıcıyı silmek istediğinizden emin misiniz? Bu işlem geri
          alınamaz.
        </p>
        <div className="flex justify-end space-x-2">
          <button
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
            onClick={kullaniciSil}
          >
            Evet
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
            onClick={closeModal}
          >
            Hayır
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modal2IsOpen}
        onRequestClose={closeModal2}
        contentLabel="Kullanıcı Silme Onayı"
        className="bg-gray-800 border border-gray-700 p-4 rounded-lg max-w-md mx-auto mt-20 shadow-lg"
      >
        <h2 className="text-xl font-semibold text-white mb-4">Emin misiniz?</h2>
        <p className="text-gray-400 mb-4">
          <strong>{selectedUsers.length}</strong> adet kullanıcıyı silmek
          istediğinizden emin misiniz? Bu işlem geri alınamaz.
        </p>
        <div className="flex justify-end space-x-2">
          <button
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
            onClick={topluKullaniciSil}
          >
            Evet
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
            onClick={closeModal2}
          >
            Hayır
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Ayarlar;
