import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const UstMenu = () => {
  const kullanici = localStorage.getItem("kullanici");
  return (
    <div className="flex flex-col w-full h-20 border-b border-gray-700 justify-center items-end p-5">
      <div className="flex flex-row">
        <div className="bg-gray-700 py-2 px-3 border-l border-y border-gray-600 rounded-l-2xl">
          Giriş Yapılan Kullanıcı: <b>{kullanici}</b>
        </div>
        <Link
          to={"/cikis"}
          className="bg-red-800 py-2 px-3 rounded-r-2xl border-y border-r border-red-600 hover:bg-red-700 transition-colors"
        >
          <FontAwesomeIcon icon={faRightFromBracket} />
        </Link>
      </div>
    </div>
  );
};

export default UstMenu;
