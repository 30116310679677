import classNames from "classnames";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSite } from "../context/SiteContext";

const ApkOlusturucu = () => {
  const { theme, darkTheme } = useSite();
  useEffect(() => {
    document.title = "APK Oluşturucu - Quantum Botnet";
  }, [])
  return (
    <div className="flex flex-col items-center justify-center">
      <a
        href="##"
        className="flex items-center mb-2 text-2xl font-semibol text-white"
      >
        {darkTheme && (
          <img className="w-32 h-32" src="./logo_beyaz.png" alt="logo" />
        )}
        {!darkTheme && (
          <img className="w-32 h-32" src="./logo_siyah.png" alt="logo" />
        )}
      </a>
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8 text-white text-center">
          <h1>
            <b>APK Oluşturucu Geliştirilme Aşamasındadır.</b>
          </h1>
          <br />
          <Link
            to={"/"}
            className={classNames(
              "w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center",
              {
                "bg-dark-blue hover:bg-dark-blue/30 focus:ring-dark-blue/50":
                  theme === "blue" && darkTheme,
                "bg-dark-red hover:bg-dark-red/40 focus:ring-dark-red/60":
                  theme === "red" && darkTheme,
                "bg-dark-green hover:bg-dark-green/30 focus:ring-dark-green/50":
                  theme === "green" && darkTheme,

                "bg-light-gray hover:bg-light-gray/30 focus:ring-light-gray/50":
                  theme === "gray" && darkTheme,

                "bg-light-blue hover:bg-light-blue/30 focus:ring-light-blue/50":
                  theme === "blue" && !darkTheme,
                "bg-light-red hover:bg-light-red/40 focus:ring-light-red/60":
                  theme === "red" && !darkTheme,
                "bg-light-green hover:bg-light-green/30 focus:ring-light-green/50":
                  theme === "green" && !darkTheme,
              }
            )}
          >
            Ana Sayfaya Dön
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ApkOlusturucu;
