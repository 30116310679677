import SiteLayout from "./layouts/SiteLayout";
import GenelBakis from "./pages/GenelBakis";
import { Routes, Route } from "react-router-dom";
import Giris from "./pages/Giris";
import Cikis from "./pages/Cikis";
import Hata404 from "./pages/Hata404";
import Botlar from "./pages/Botlar";
import Bankalar from "./pages/Bankalar";
import Ayarlar from "./pages/Ayarlar";
import Vnc from "./pages/Vnc";
import ApkOlusturucu from "./pages/ApkOlusturucu";

function App() {
  return (
    <Routes>
      <Route path="/" element={<SiteLayout />}>
        <Route index element={<GenelBakis />} />
        <Route path="/genel-bakis" element={<GenelBakis />} />
        <Route path="/botlar" element={<Botlar />} />
        <Route path="/bankalar" element={<Bankalar />} />
        <Route path="/ayarlar" element={<Ayarlar />} />
        <Route path="/vnc" element={<Vnc />} />
        <Route path="/apk-olusturucu" element={<ApkOlusturucu />} />
      </Route>
      <Route path="*" element={<Hata404 />} />
      <Route path="/giris" element={<Giris />} />
      <Route path="/cikis" element={<Cikis />} />
    </Routes>
  );
}

export default App;
